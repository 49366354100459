<template>
  <div class="space-y-4">
    <asom-alert v-if="computedError" variant="error" :error-message="computedError" />
    <h3 class="subheader">
      {{ $t("screens.inventory.Main.overview.title") }}
    </h3>
    <asom-card title="Tracked Items Inventory">
      <template #header>
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <div class="flex flex-wrap content-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Tracked Items Inventory
            </h3>
          </div>
          <div class="flex flex-row-reverse">
            <asom-export-to-excel
              fileName="Tracked Items Inventory"
              :fields="columns.fields"
              :labels="columns.labels"
              :data="columns.data"
            />
          </div>
        </div>
      </template>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field label="Inventory Type Name">
          <asom-input-text v-model="filters.inventoryTypeName" />
        </asom-form-field>
        <asom-form-field label="Location Name">
          <asom-input-text v-model="filters.locationName" />
        </asom-form-field>
        <asom-form-field label="Expiry Date">
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <div class="flex items-end space-x-2">
          <asom-q-r-code-scanner @detect="qrCodeResult">
          </asom-q-r-code-scanner>
          <asom-button
            text="Reset Filters"
            variant="secondary"
            @click="resetFilters"
          />
          <asom-button text="Apply" @click="getPageData(true)" />
        </div>
      </template>
    </asom-card>
    <div class="pt-8">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'inventoryTypeName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
          'remarks',
          canUpdateDigitalRecords && 'action',
        ]"
        :data="itemList"
        :filterable="false"
        :sortableColumns="[
          'inventoryTypeName',
          'itemDescription',
          'amount',
          'primaryLocationName',
          'secondaryLocationName',
          'expiryDate',
        ]"
      >
        <template v-slot:header_amount>Quantity</template>
        <template v-slot:primaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:secondaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:header_inventoryTypeName>Item Type</template>
        <template v-slot:header_primaryLocationName>Primary Location</template>
        <template v-slot:header_secondaryLocationName
          >Secondary Location</template
        >
        <template v-slot:expiryDate="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span v-if="scopedSlots.data"
            ><remarks-tooltip :text="scopedSlots.data"/></span
          ><span v-else>{{ "NA" }}</span>
        </template>
        <template v-slot:action="scopedSlots">
          <div class="flex flex-col gap-1 w-48">
            <asom-button
              size="sm"
              outline
              text="QR"
              @click="viewQR(scopedSlots.rowData)"
            />
            <asom-button
              v-if="canUpdateDigitalRecords"
              size="sm"
              outline
              text="Update"
              @click="updateItem(scopedSlots.rowData)"
            />
            <asom-button
              size="sm"
              outline
              text="Use"
              @click="useItemClicked(scopedSlots.rowData)"
            />
          </div>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </div>
    <asom-card title="Activity Log">
      <asom-client-table
        v-if="!isLoading"
        :columns="[
          'transactionNo',
          'officerName',
          'date',
          'time',
          'inventoryTypeName',
          'supplyDate',
          'expiryDate',
          'primaryLocationName',
          'secondaryLocationName',
          'transactionType',
          'amount',
          'remarks',
        ]"
        :data="activityList"
        :filterable="false"
      >
        <template v-slot:header_inventoryTypeName>Item Type</template>
        <template v-slot:header_amount>Quantity</template>
        <template v-slot:header_primaryLocationName>Primary Location</template>
        <template v-slot:header_secondaryLocationName
          >Secondary Location</template
        >
        <template v-slot:date="scopedSlots">
          <span>{{
            displayUtcDate(get(scopedSlots.rowData, "transactionDate"))
          }}</span>
        </template>
        <template v-slot:time="scopedSlots">
          <span>{{
            displayUtcTime(get(scopedSlots.rowData, "transactionDate"))
          }}</span>
        </template>
        <template v-slot:expiryDate="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:supplyDate="scopedSlots">
          <span>{{
            scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"
          }}</span>
        </template>
        <template v-slot:amount="scopedSlots">
          <span>{{
            activityLogTranasctionAmount(
              get(scopedSlots.rowData, "amount"),
              get(scopedSlots.rowData, "transactionTypeEnum")
            )
          }}</span>
        </template>
        <template v-slot:primaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:secondaryLocationName="scopedSlots">
          <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
        </template>
        <template v-slot:remarks="scopedSlots">
          <span v-if="scopedSlots.data"
            ><remarks-tooltip :text="scopedSlots.data"/></span
          ><span v-else>{{ "NA" }}</span>
        </template>
      </asom-client-table>
      <div v-else class="text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
    </asom-card>
    <use-item-modal
      v-model:showModal="showUseItemModal"
      :inventoryId="selectedItemId"
      :inventoryName="selectedItemName"
      @success="getPageData"
      @fail="$scrollTop"
      :inventoryGroup="3"
    />
    <update-item-modal
      v-model:showModal="showUpdateModal"
      :inventory="selectedItem"
      @success="getPageData"
      @fail="$scrollTop"
      :inventoryGroup="3"
    />
    <view-q-r-modal
      v-model:showModal="showQRCode"
      :qrCodeString="get(selectedItemForQR, 'qrCodeString', '')"
      :selectedItemName="get(selectedItemForQR, 'inventoryTypeName', 'QR Code')"
    />
  </div>
</template>

<script>
import get from "lodash.get";
import {
  displayUtcDate,
  displayUtcTime,
} from "@/helpers/dateTimeHelpers";
import { activityLogTranasctionAmount } from "../../../../helpers/inventoryActivityHelper";
import { mapGetters, mapState } from "vuex";
import UseItemModal from "../UseItemModal.vue";
import UpdateItemModal from "../UpdateItemModal.vue";
import RemarksTooltip from "../../../_RemarksTooltip.vue";
import ViewQRModal from "../ViewQRModal.vue";
import { getInventoryTypeDetails } from "../../../../services/inventory.service";
import { InventoryGroups } from "../../../../constants/APIEnums/inventoryEnums";
import { checkScanResults } from "../inventoryQRScannerMixin";
import { setStorage, getStorage } from "@/helpers/sessionStorage";
import { parseDateTimeUTC } from "@/helpers/dateTimeHelpers";
import moment from "moment";

export default {
  name: "TrackedItemsMainPage",
  mixins: [checkScanResults],
  components: {
    UseItemModal,
    UpdateItemModal,
    RemarksTooltip,
    ViewQRModal,
  },
  data() {
    return {
      filters: {
        inventoryTypeName: get(this.$route.params, "inventoryTypeName", null),
        locationName: null,
        dateRange: {
          start: moment()
            .add(-1, "months")
            .toDate(),
          end: moment().add(0, 'days').toDate(),
        },
      },
      selectedItem: null,
      showUseItemModal: false,
      showUpdateModal: false,
      showQRCode: false,
      selectedItemForQR: null,
      itemList: [],
      findInventoryTypeError: null,
    };
  },
  computed: {
    ...mapState({
      _itemList: (state) =>
        state.inventoryManagement.inventory.digitalRecordItems,
      activityList: (state) =>
        state.inventoryManagement.inventory.digitalRecordItemsActivityLog,
      isLoading: (state) => state.inventoryManagement.inventory.isLoading,
      getInvnetoryError: (state) => state.inventoryManagement.inventory.error,
    }),
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
      canUpdateDigitalRecords: "auth/canUpdateDigitalRecords",
    }),
    computedError() {
      return this.findInventoryTypeError || this.getInvnetoryError;
    },
    columns() {
      const fields = [
        "inventoryTypeName",
        "itemDescription",
        "amount",
        "primaryLocationName",
        "secondaryLocationName",
        "expiryDate",
      ];
      const labels = [
        "Item Type",
        "Item Description",
        "Quantity",
        "Primary Location Name",
        "Secondary Location Name",
        "Expiry Date",
      ];
      const data = [];
      this._itemList.forEach((element, i) => {
        data[i] = fields.reduce((accm, field) => {
          let d = element[field];
          if (field == "expiryDate") {
            d = displayUtcDate(d);
          }
          accm[field] = d;
          return accm;
        }, {});
      });
      return {
        fields,
        labels,
        data,
      };
    },
    selectedItemId() {
      return get(this.selectedItem, "inventoryId");
    },
    selectedItemName() {
      return get(this.selectedItem, "inventoryTypeName");
    },
    queryParams() {
      let params = {
        lineId: this.lineId,
        inventoryTypeName: this.filters.inventoryTypeName,
        locationName: this.filters.locationName,
      };
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = parseDateTimeUTC(this.filters.dateRange.start, true);
        params["searchDateTo"] = parseDateTimeUTC(this.filters.dateRange.end, false);
      }
      return params;
    },
  },
  watch: {
    '_itemList': function(){
      this.itemList = this._itemList;
    }
  },
  mounted() {
    const searchVal = getStorage("tracked-items");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }

    this.$nextTick(() => {
      this.getPageData();
      this.itemList = this._itemList;
    })
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    activityLogTranasctionAmount,
    resetFilters() {
      this.filters.dateRange = null;
      this.filters.inventoryTypeName = null;
      this.filters.locationName = null;
    },
    qrCodeResult(data) {
      let itemId = data;
      this.getTypeDetails(itemId);
    },
    async getTypeDetails(itemId) {
      this.findInventoryTypeError = null;
      const result = await getInventoryTypeDetails({
        inventoryTypeId: itemId,
        stationId: this.stationId,
      });
      if (result.success) {
        let itemDetails = get(result.payload, "inventoryTypeDetailsViewModel");
        this.filters.inventoryTypeName = this.checkPage(
          itemDetails,
          InventoryGroups.SERIALISED_TAG.VALUE
        );
      } else {
        this.findInventoryTypeError = result.payload;
        this.$scrollTop();
        return;
      }
    },
    async getPageData(status) {
      if(status) setStorage("tracked-items", this.filters);
      const resp = await this.$store.dispatch(
        "inventoryManagement/inventory/loadDigitalRecordItems",
        this.queryParams
      );
      if (!resp.success) {
        this.$scrollTop();
      }
    },
    useItemClicked(item) {
      this.selectedItem = item;
      this.showUseItemModal = true;
    },
    updateItem(item) {
      this.selectedItem = item;
      this.showUpdateModal = true;
    },
    viewQR(item) {
      this.selectedItemForQR = item;
      this.showQRCode = true;
    },
  },
};
</script>
